





























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import SelectPop from "@/views/bigData/bigDataBottom/bigDataBottomCenter/components/selectPop.vue";
import {FrameData, Frame, HouseList, BigData, VideoList} from "@/common/Views/bigData/bigData";
import {VideoJsPlayer} from "video.js";
import {Util} from "@/common/Views/comm/util";

declare const document: any;
declare const Hls: any;
@Component({
    components: {SelectPop}
})
export default class HelloWorld extends Vue {
    private carousel: number = 0;
    @Prop() HouseList!: HouseList[];
    @Prop({default: []}) videoList!: VideoList[][];

    private created() {
        this.$nextTick(() => {
            this.carousel = (<HTMLElement> this.$refs.box).clientHeight;
        });
    }

    private selectId: string = "";
    private dialogVideoUrl: string = "";

    private showId: string = "-1";

    private dialogTableVisible: boolean = false;

    private choose(item: Frame) {
        this.selectId = item.id;

        this.$emit("chooseList", this.selectId);
    }

    private change(index: number) {
        // this.player.forEach((value: VideoJsPlayer|null) => value && value.dispose());
        this.initVideo(index);
    }


    private bigVideo: VideoJsPlayer | null = null;

    private showBigVideo(ev: Event, item: VideoList, index: number) {
        if ((<HTMLElement> ev.target).nodeName.toLowerCase() === "video" && item.m3u8Url) {

            // if ((<HTMLElement> ev.target).nodeName.toLowerCase() === "object") {
            this.showId = item.id;
            const oLi: HTMLElement = <HTMLElement> document.querySelector(`#li${item.id}`);
            const oUl: HTMLElement = <HTMLElement> document.querySelector(`#bigUl`);

            oLi.style.transition = ".3s";
            oLi.style.width = oUl.clientWidth + "px";
            oLi.style.height = oUl.offsetHeight - Util.remToPx(.2)  + "px";
            oLi.style.top = "0.2rem";
            oLi.style.left = "0rem";
            oLi.style.zIndex = "999";
            oLi.style.position = "absolute";
            // }

        }

    }

    private close(item: VideoList, index: number) {

        if (this.dialogTableVisible) {

            this.dialogTableVisible = false;

        } else {

            const oLi: HTMLElement = <HTMLElement> document.querySelector(`#li${item.id}`);


            oLi.style.width = "100%";
            oLi.style.height = "100%";
            oLi.style.top = "0";
            oLi.style.left = "0";
            oLi.style.zIndex = "1";
            oLi.style.position = "relative";
            this.showId = "-1";
        }


    }

    @Watch("dialogTableVisible")
    private handleDialogTableVisible() {
        if (!this.dialogTableVisible && this.bigVideo) {
            this.bigVideo.dispose();
        }
    }

    private player: Array<null> | VideoJsPlayer[] = [];

    private playVideo(id: string, rtmpUrl: string): VideoJsPlayer | null {
        try {

            const video = document.getElementById(id);
            const videoSrc = rtmpUrl;
            if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function() {
                    video.play();
                });
            }
            // return player;
        } catch (e) {
            console.log(e);
        }

        return null;
    }

    private initVideo(index: number = 0) {
        this.$nextTick(() => {

            this.videoList[index].forEach(value => {
                // if (value.id === '2') {
                //     value.rtmpUrl = 'rtmp://58.200.131.2:1935/livetv/hunantv';
                // }
                if (value.m3u8Url) {
                    this.player.push(<VideoJsPlayer & null> this.playVideo("video" + value.id, value.m3u8Url));
                } else {
                    this.player.push(<VideoJsPlayer & null> null);
                }

            });


            if (this.videoList[index].length < 4) {
                const list: any[] = [];
                list.length = 4 - this.videoList[index].length;
                list.fill({rtmpUrl: ""}, 0, list.length);

                this.videoList[index] = this.videoList[index].concat(...(<any> list));

                this.$forceUpdate();
            }

        });
    }

    private destroyed() {
        this.player.forEach((value: VideoJsPlayer | null) => {
            if (value) {
                value.dispose();
            }
        });
    }

    @Watch("HouseList")
    private handleHouseList() {
        this.selectId = "";
        this.$emit("chooseList", "");
    }

    @Watch("videoList", {immediate: true})
    private handelVideoList() {
        this.initVideo();
    }

    private bigVideoData: VideoList|null = null;
    private diaHeight: number = 0;
    private diaWidth: number = 0;

    private launchFullScreen(item?: VideoList) {
        /*const element: any = document.getElementById(id);
        if (!element) {
            return;
        }
        const isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if (!isFullscreen) {//进入全屏,多重短路表达式
            (element.requestFullscreen && element.requestFullscreen()) ||
            (element.mozRequestFullScreen && element.mozRequestFullScreen()) ||
            (element.webkitRequestFullscreen && element.webkitRequestFullscreen()) || (element.msRequestFullscreen && element.msRequestFullscreen());

        } else { //退出全屏,三目运算符
            document.exitFullscreen ? document.exitFullscreen() :
                document.mozCancelFullScreen ? document.mozCancelFullScreen() :
                    document.webkitExitFullscreen ? document.webkitExitFullscreen() : "";
        }*/

        if (this.dialogTableVisible) {
            this.dialogTableVisible = false;
            return;
        }

        this.dialogTableVisible = true;
        this.bigVideoData = item!;
        this.diaHeight = document.documentElement.offsetHeight;
        this.diaWidth = document.documentElement.offsetWidth;

        this.$nextTick(() => {
            this.bigVideo = this.playVideo("dialogVideo", item!.m3u8Url);
        });
    }
}
