


















import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class HelloWorld extends Vue {
    @Prop()title!: string;
    @Prop({default: .3})popHeight!: number;
    @Prop({default: 2})popWidth!: number;
    @Prop()list!: any[];
    @Prop({default: 'name'})elementName!: string;
    @Prop({default: 'id'})elementId!: string;
    @Prop({default: ''})selectId!: string;
    @Prop({default: true})fillAll!: boolean;

    private showItem: boolean = false;
    private height: string = '0rem';

    private created() {
        addEventListener("click", ()=>{
            this.height = '0rem';

            setTimeout(()=>{
                this.showItem = false;
            }, 300)
        })
    }

    private selectName: string = '';
    private selectIdB: string = '';

    private choose(item: any) {
        this.selectIdB = item[this.elementId];
        this.selectName = item[this.elementName];
        this.$emit('choose', item);
    }

    private show() {

        if (this.showItem) {
            this.height = '0rem';

            setTimeout(()=>{
                this.showItem = false;
            }, 300)
        } else {
            this.showItem = true;




            setTimeout(()=>{
                const children = (<HTMLElement> this.$refs.ulItem).children;
                if (children.length > 6) {
                    this.height = "2rem";
                } else {
                    this.height = (((<HTMLElement>children[1]).offsetHeight || (<HTMLElement>children[0]).offsetHeight) * children.length) + 'px';
                }
            }, 0);
        }
    }

    @Watch("selectId", {immediate: true})
    public handleSelectId() {

        this.selectIdB = this.selectId;

        for (const i of this.list) {
            if (this.selectId === i[this.elementId]) {
                this.selectName = i[this.elementName];
                break;
            }
        }
    }

    @Watch("list", {immediate: true, deep: true})
    public handleListAddAll() {
        if(!this.list) return;

        let flag = true;
        for (const i in this.list) {
            if (this.list[i][this.elementId] === "") {
                flag = false;
                break;
            }
        }

        if (flag && this.fillAll) {
            this.list.unshift({[this.elementId]: '', [this.elementName]: "全部"});
        }


        for (const i in this.list) {
            if (this.selectId === this.list[i][this.elementId]) {
                this.selectName = this.list[i][this.elementName]
            }
        }

    }

}
